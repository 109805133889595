<template>
  <div :class="col" class="mt-3">

    <vb-card class="passengers" :card="{
      title: (field_unlock.sesso === 'M' ? 'Sig. ' : field_unlock.sesso === 'F' ? 'Sig.ra ' : '') + field_unlock.nome + ' ' + field_unlock.cognome,
      subtitle: 'Viaggiatore ' + pax.id + ' - ' + this.tipPax(field_unlock.tipPax),
    }">
      <div class="passengers__text">
        <ul>
          <li>Data di nascita: <strong class="semibold">{{ formattedDataNascita() }}</strong></li>
          <li>Nazionalità: <strong class="semibold">{{ field_unlock.nazionalita }}</strong></li>
          <li v-if="field_unlock.countCell && field_unlock.numCell">Cellulare: <strong class="semibold">+{{ field_unlock.countCell }} {{ field_unlock.numCell }}</strong></li>
        </ul>
      </div>
    </vb-card>

  </div>

</template>

<script>
import Card from '@library/blocks/card'
import moment from 'moment'

export default {
  name: 'card-pax',

  props: {
    pax: Object,
    col: {
      type: String,
      default: '',
    }
  },

  components: {
    'vb-card': Card,
  },

  data() {
    return {
      field_certified: ['tipPax', 'sesso', 'nome', 'cognome', 'dataNascita', 'nazionalita', 'countCell', 'numCell'],
      field_unlock: {},
      field_lock: {},
    }
  },

  created() {
    this.filter_pax()
  },

  mounted() {

  },

  methods: {

    filter_pax() {

      this.pax.details.fields.forEach(field => {
        let field_certified = this.field_certified.includes(field.name);
        let field_obj = field_certified ? this.field_unlock : this.field_lock;
        if(field.value) field_obj[field.name] = field.value;
      })
    },

    tipPax(tipPax){
      switch(tipPax){

        case 'MR':
        case 'MS':
          return 'ADULTO';

        case 'CHD':
          return 'BAMBINO';

        case 'INF':
          return 'INFANTE';

        default:
          return '';

      }
    },

    formattedDataNascita(){
      return moment(this.field_unlock.dataNascita).format('DD-MM-YYYY')
    }
  }
}
</script>

<style lang="scss" scoped>

  .passengers {
    min-height: 100%;
    border: 0;

    &__text {
      ul {
        padding-left: $grid-gutter-width/4;
        list-style: inside;
        list-style-type: circle;
        margin-top: $grid-gutter-width/6;
        margin-bottom: 0;
      }
    }
  }

  ::v-deep {

    .vb-card {

      &__head {
        display: flex;
        flex-direction: column;
      }

      &__subtitle {
        font-size: $font-size-sm;
        margin-top: $grid-gutter-width/6;
        font-weight: $vb-font-weight-semibold;
      }
    }
  }

</style>